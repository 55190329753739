import { SkipContainer, SkipText, BtnMaxPremium, MaxPremiumText, MaxPremiumSubText, BlockContent, BlockContainer, BlockWrapper, BlockBgd, BlockHeader, UpgradeHeaderTextBox, BlockTextHeader} from '../components/AppComponents/appElements';

function TooManyMixesPage ({goToMainMenu, goToSettings}) {
	return (
		<BlockContainer>
			<BlockWrapper>
				<BlockBgd/>
				<BlockContent>
					<BlockHeader>
						<UpgradeHeaderTextBox>
							Upgrade now!	
						</UpgradeHeaderTextBox>					
					</BlockHeader>
					<BlockTextHeader>
						You have used all your free minutes for the two week period.
					</BlockTextHeader>	
					<BlockTextHeader>
						<BtnMaxPremium onClick={goToSettings}>
      				<MaxPremiumText> Premium Plan </MaxPremiumText>
      				<MaxPremiumSubText> Unlimited mixes, no ads </MaxPremiumSubText>
    				</BtnMaxPremium>
					</BlockTextHeader>
					<SkipContainer>
						<SkipText onClick={goToMainMenu} >
							Skip	
						</SkipText>
					</SkipContainer>
				</BlockContent>
			</BlockWrapper>
		</BlockContainer>
	)
}

export default TooManyMixesPage