import { useState } from "react"
import { Browser } from '@capacitor/browser'
import {SettingsContainer,BtnSettingsContainer } from "./SettingsElements"
//import { PremiumSubText,PremiumText,BtnPremium,SettingsContainer,BtnSettingsContainer } from "./SettingsElements"
//import {SubSettingsContent,PreviousButtonContainer} from "../AppComponents/appElements";
import AccountPage from "../../pages/accountPage"
import TermsInAppPage from "../../pages/termsInAppPage"
import PrivacyInAppPage from "../../pages/privacyInAppPage"
//import PlansPage from "../../pages/plansPage"
import { UserContext } from "../../context/UserContext"
//import { FaAngleLeft } from 'react-icons/fa';

//function SubscribeButton({setCurrentSelection}) {
  //return (
    //<BtnPremium onClick={() => setCurrentSelection('plan')}>
      //<PremiumText>Join Premium Plan</PremiumText>
      //<PremiumSubText>Unlimited mixes, no ads</PremiumSubText>
    //</BtnPremium>
  //)
//}

function SettingsMenu({logOutClick, setCurrentSelection, accountType}) {
  //const {accountType} = useContext(UserContext)

  //const openSmoothiSite = async () => {
  //  await Browser.open({ url: 'https://smoothi.io/' });
  //};
  const openSmoothiSupportSite = () => {
    Browser.open({ url: 'https://smoothi.io/support/' });
  };
  return (
    <div>
      <SettingsContainer>
        <BtnSettingsContainer onClick={()=>setCurrentSelection('account')}>
          Account
        </BtnSettingsContainer>
        <BtnSettingsContainer onClick={openSmoothiSupportSite}>
          Support
        </BtnSettingsContainer>
        <BtnSettingsContainer onClick={()=>setCurrentSelection('privacy')}>
          Privacy Policy
        </BtnSettingsContainer>
        <BtnSettingsContainer onClick={()=>setCurrentSelection('terms')}>
          Terms and Conditions
        </BtnSettingsContainer>
        <BtnSettingsContainer onClick={logOutClick}>
          Log Out
        </BtnSettingsContainer>
        
      </SettingsContainer>
    </div>
  )
}

function SettingsSection({defaultSettingsSelection, logOutClick, isLoggedIn, refetchUserData, email, username, accountType, userSubscribed, subscriptionEnding, stripeCustomerId, setAccountDeleted}) {
  const [currentSelection, setCurrentSelection] = useState(defaultSettingsSelection)

  const backToSettingsMenu = () => {
    setCurrentSelection('menu')
  }

  const goToPlansPage = () => {
    setCurrentSelection('plan')
  }

  if (currentSelection === 'menu') {
    return <SettingsMenu logOutClick={logOutClick} isLoggedIn={isLoggedIn} setCurrentSelection={setCurrentSelection} accountType={accountType}/>
  }
  if (currentSelection === 'account') {
    return (
      <UserContext.Provider value={{ 
        email: email,
        username: username,
        accountType: accountType,
        userSubscribed: userSubscribed,
        subscriptionEnding: subscriptionEnding,
        stripeCustomerId: stripeCustomerId,
        setAccountDeleted: setAccountDeleted,
        refetchUserData: refetchUserData
        }}>
        <AccountPage backToSettingsMenu={backToSettingsMenu} goToPlansPage={goToPlansPage}/>
      </UserContext.Provider>
    )
  }
  if (currentSelection === 'terms') {
    return <TermsInAppPage backToSettingsMenu={backToSettingsMenu}/>
  }
  if (currentSelection === 'privacy') {
    return <PrivacyInAppPage backToSettingsMenu={backToSettingsMenu}/>
  }
  
  else return <div>Error</div>
}

export default SettingsSection

//TODO: This was not working in SettingsSection so we got lazy and took it out
//if (currentSelection === 'plan') {
  //return( 
    //<div>
      //<PreviousButtonContainer style={{cursor: 'pointer'}}>
        //<FaAngleLeft onClick={backToSettingsMenu} />
      //</PreviousButtonContainer>
    //<SubSettingsContent>
      //<PlansPage backToSettingsMenu={backToSettingsMenu}/>
   // </SubSettingsContent>
  //</div>
  //)
//}
//{(accountType === process.env.REACT_APP_ACCOUNT_TYPE_0) ? <SubscribeButton setCurrentSelection={setCurrentSelection} />: <></>}