import { useEffect, useRef, useState } from "react"
import { gql, useQuery, useMutation } from '@apollo/client';
import axios from 'axios';

//import { App as AppCap} from '@capacitor/app';
import Marquee from "react-fast-marquee";

import ChoicesSection from "../components/ChoicesSection"
import MenuSection from "../components/MenuSection"
import PlayerPage from "./playerPage"
import SettingsSection from "../components/SettingsSection"
import PreviewSection from "../components/PreviewSection"
import {PlayerBarContainer,MiniContentContainer, PlayIcon,PauseIcon, PlayButton, HeartIcon, FullHeartIcon, PlayerBarContent,ArtistContainer,TitleContainer } from "../components/PlayerBar/PlayerBarElements"
import Footer from '../components/Footer';
import WebLogo from '../components/WebLogo';
import { BarLogo, ImageBg, SmoothiContainer, ChoosingContainer, ChoosingContent,SmoothiContent, SmoothiPlayerContent, ExploreContent, SettingsContent, EmptyHeartPlayerBar, FullHeartPlayerBar } from '../components/AppComponents/appElements';
import Logo from '../images/smoothi-logo-circle-white-on-clear.svg'
import Player from "../components/PlayerSection"
import { AudioContext } from "../context/AudioContext"
//import { UserContext } from "../context/UserContext"
//import { UserContextActions } from "../context/UserContextActions"
import TooManyMixesPage from "./tooManyMixesPage";
import ErrorPageInApp from "./errorPageInApp";
import LoadingComponent from "../components/LoadingComponent";
import VerifyEmailPage from "./verifyEmailPage";
import WrongCountryPage from "./wrongCountryPage";

const GET_USER_PROFILE = gql`
	query GetUserProfile {
		customerSubscribed {
			subscribed
    	subscriptionEnding
		}
		customer {
			stripeCustomerId
		}
	  userProfile {
	    profileName
			onePixelUrl
	    user{
	      username
				email
				userType
	    }
	  }
		me {
			verified
		}
	}
`

const GET_MIX_LIKED = gql`
  query GetMixLiked($mixId: String!) {
    mixLiked(mixId: $mixId) 
  }
`;

const CREATE_MIX_LIKE = gql`
  mutation CreateMixLike($mixId: String!, $timeOfLike: Time){
    createMixLike(mixId:$mixId, timeOfLike:$timeOfLike){
      mixLikeSaved
    }
  }
`

const DELETE_MIX_LIKE = gql`
  mutation DeleteMixLike($mixId: String!){
    deleteMixLike(mixId:$mixId){
      mixLikeDeleted
    }
  }
`

const PRESSED_PLAY = gql`
  mutation CreatePlayTime($mixRequestId: Int!, $timeOfPress: Time, $pressType: String!){
    createPlayTime(mixRequestId:$mixRequestId, timeOfPress:$timeOfPress, pressType:$pressType){
			playTimeSaved
    }
  }
`

function time_convert_for_like(sec_num) {
  let total_seconds = Math.floor(sec_num)
  let hours = Math.floor(total_seconds / 3600)
  let minutes = Math.floor((total_seconds - hours * 3600)/60)
  let seconds = total_seconds - hours * 3600 - minutes * 60
  let time_string = ""
  time_string = time_string + hours + ":"
  if (minutes < 10) {
    time_string = time_string + "0"
  }
  time_string = time_string + minutes + ":"
  if (seconds < 10) {
    time_string = time_string + "0"
  }
  time_string = time_string + seconds
  return time_string
}

function Heart({mixId, mixLiked, setMixLiked, handleLikeClick, handleDeleteLikeClick}) {
	const { loading, error, data, refetch } = useQuery(GET_MIX_LIKED, {
    variables: { mixId },
  });

	useEffect(()=>{
		if (data) {
			refetch({mixId: mixId})
			if (data.mixLiked){
				setMixLiked(true)
			} else {
				setMixLiked(false)
			}
		}
	}, [data, mixId, refetch, setMixLiked])

	if (loading) {
    return <div/>
  }
  else if (error) {
    return <div/>
  }

	if (mixLiked) {
		return(
		<EmptyHeartPlayerBar>
			<FullHeartIcon onClick={handleDeleteLikeClick}/>
		</EmptyHeartPlayerBar> )
	}
	else {
		return (
			<FullHeartPlayerBar>
				<HeartIcon onClick={handleLikeClick}/> 
			</FullHeartPlayerBar>
		)
	}
}

function PlayerButtons({titleMarquee, artistMarquee,mixId, audioInstance, currentSong, handleLikeClick, handleDeleteLikeClick,  isPlaying, goToMusicPlayer, mixLiked, setMixLiked}) {

	const pressPlayerButton = () => {
    if (audioInstance.paused === false) {
      audioInstance.pause()
    }
    else {
      audioInstance.play()
    }
  }

	return(
		<PlayerBarContainer>
			<PlayerBarContent>
			<BarLogo src={Logo} style={{width:"37px", marginRight:'15px', marginBottom:"0px", marginTop:"0px"}}/>
				
				<MiniContentContainer onClick={goToMusicPlayer} style={{cursor: 'pointer'}}>
					<Marquee play={1} 
						speed={30} 
						loop={titleMarquee}
						delay={1}
						
						style={{alignItems:'right', display:'flex', justifyContent:'left'}} >
						<TitleContainer>
							{currentSong.songTitle}
						</TitleContainer>
					</Marquee >	
					<Marquee play={1} speed={30} delay={1} loop={artistMarquee}  style={{alignItems:'right', display:'flex', justifyContent:'left'}}>
					
						<ArtistContainer >
							{currentSong.artist}
						</ArtistContainer>
					</Marquee>
				</MiniContentContainer>
				<PlayButton style={{cursor: 'pointer'}} onClick={pressPlayerButton}> 
					{(isPlaying) ? <PauseIcon/>:<PlayIcon/>} 
				</PlayButton>
				<Heart mixId={mixId} mixLiked={mixLiked} setMixLiked={setMixLiked} handleLikeClick={handleLikeClick} handleDeleteLikeClick={handleDeleteLikeClick}/>
			</PlayerBarContent>
		</PlayerBarContainer> 
	)
}

function SmoothiAppPlayer({currentPage, setCurrentPage, userChoices, userPreview, userSubscribed, goToTooManyRequests}) {
	const [currentSong, setCurrentSong] = useState({'songTitle':'', 'artist':''})
	const [isPlaying, setIsPlaying] = useState(false)
	const [mixRequestId, setMixRequestId] = useState(null)
	const [mix, setMix] = useState(null)
	const [mixLiked, setMixLiked] = useState(false)
	const [currentTime, setCurrentTime] = useState(0)
	const [title, setTitle] = useState('')
	const [percentage, setPercentage] = useState(0)
	const [artistMarquee, setArtistMarquee] = useState(0)
	const [titleMarquee, setTitleMarquee] = useState(0)

	const audioRef = useRef(null)
	const [songUrl, setSongUrl] = useState('')

	const [likeMixFunction] = useMutation(CREATE_MIX_LIKE)
	const [deleteMixLikeFunction] = useMutation(DELETE_MIX_LIKE)

	const [pressPlayFunction] = useMutation(PRESSED_PLAY)

	const pressedPlay = () => {
		setIsPlaying(true)
		const audioInstance = audioRef.current
		const timeOfPress = time_convert_for_like(audioInstance.currentTime)

		pressPlayFunction({ variables: { mixRequestId: mixRequestId, timeOfPress: timeOfPress, pressType:"PLAY" } })
	}

	const pressedPause = () => {
		setIsPlaying(false)
		const audioInstance = audioRef.current
		const timeOfPress = time_convert_for_like(audioInstance.currentTime)
		if (mixRequestId) {
			pressPlayFunction({ variables: { mixRequestId: mixRequestId, timeOfPress: timeOfPress, pressType:"PAUSE" } })
		}
	}

	const updatePlayerAudio = () => {
		const audioInstance = audioRef.current
		if (audioInstance.paused === false) {
			setIsPlaying(true)
		}
		else {
			setIsPlaying(false)
		}
		for (let i = 0; i < mix.songPlaylist.length - 1; i++) {
			if ((mix.timesList[i + 1].startTime > audioInstance.currentTime) && audioInstance.currentTime > mix.timesList[i].startTime) {
				setCurrentSong(mix.songPlaylist[i])
				if (currentSong.artist.length>30) {
					setArtistMarquee(0)	
				}
				else{
					setArtistMarquee(1)		
				}
				if (currentSong.songTitle.length>30) {
					setTitleMarquee(0)	
				}
				else{
					setTitleMarquee(1)		
				}
				break
			}
		}
		// floors are to that renders aren't triggered as often ;) 
		setCurrentTime(Math.floor(audioInstance.currentTime))
		if (audioInstance.duration > 0){
			setPercentage(Math.floor(1000*audioInstance.currentTime/audioInstance.duration)/10 )
		}
	}

  const handleLikeClick = () => {
		const audioInstance = audioRef.current
    if (mix.mixId && audioInstance.currentTime) {
      try {
        const timeForLike = time_convert_for_like(audioInstance.currentTime)
        likeMixFunction({ variables: { mixId: mix.mixId, timeOfLike: timeForLike } }).then((result)=>{
					if (result.data.createMixLike.mixLikeSaved) {
						setMixLiked(true)
					}
				})
      }
      catch (error) {
        console.log(error)
      }
    }
    else {
      console.log("No mix or time")
    }
  }

  const handleDeleteLikeClick = () => {
    if (mix.mixId) {
      try {
        deleteMixLikeFunction({ variables: { mixId: mix.mixId } }).then((result)=>{
						if (result.data.deleteMixLike.mixLikeDeleted) {
							setMixLiked(false)
						}
					}
				)
      }
      catch (error) {
        console.log(error)
      }
    }
    else {
      console.log("No mix")
    }
  }

	const resetPlayingValues = () => {
		setIsPlaying(false)
		setCurrentTime(0)
		setPercentage(0)
	}

	const PlayerPageContainer = () => {
		return(
			<SmoothiContainer>
				<ImageBg />
				<SmoothiContent>
					<PlayerPage userChoices={userChoices} userPreview={userPreview}  
						setMixLiked={setMixLiked} setMix={setMix}
						setCurrentSong={setCurrentSong}
						setSongUrl={setSongUrl}
						goToMusicPlayer={() => { setCurrentPage('musicPlayer')}}
						userSubscribed={userSubscribed}
						goToTooManyRequests={goToTooManyRequests}
						resetPlayingValues={resetPlayingValues}
						goToMainMenu={() => { setCurrentPage('mainMenu')}}
						pressedPause={pressedPause}
						/>
				</SmoothiContent>
			</SmoothiContainer>
		)
	}

	const MusicPlayer = () => {
		return (
			<SmoothiContainer>
				<ImageBg />
				<SmoothiPlayerContent>
					<Player audioRef={audioRef} mixLiked={mixLiked} 
						currentSong={currentSong} currentTime={currentTime} 
						percentage={percentage} handleLikeClick={handleLikeClick} 
						handleDeleteLikeClick={handleDeleteLikeClick} isPlaying={isPlaying}
						title={title} mix={mix}/>
				</SmoothiPlayerContent>
			</SmoothiContainer>
		)
	}

	const ReturnMenu = () => {
		switch (currentPage){
			case 'playerPage':
				return <PlayerPageContainer/>
			case 'musicPlayer':
				return <MusicPlayer/>
			default:
				return (
					<div>no show</div>
				)
		}
	}

	return(
		<div>
			<AudioContext.Provider value={{ 
					setCurrentSong: setCurrentSong,
					setTitle: setTitle,
					mixRequestId: mixRequestId,
					setMixRequestId: setMixRequestId,
					mix: mix
					}}>
				<audio ref={audioRef} src={songUrl} 
					onTimeUpdate={updatePlayerAudio}
					onPlay={pressedPlay}
					onPause={pressedPause}
					onLoadedData={updatePlayerAudio}/>
				{(currentPage === 'musicPlayer' || currentPage === 'playerPage') ? <ReturnMenu/>:<div/>}
			</AudioContext.Provider>
			{(mix !== null && currentPage !== 'musicPlayer') ? 
				<PlayerButtons mixId={mix.mixId} audioInstance={audioRef.current} 
					currentSong={currentSong} 
					 isPlaying={isPlaying} 
					 artistMarquee={artistMarquee}
					 titleMarquee={titleMarquee}
					mixLiked={mixLiked} setMixLiked={setMixLiked} 
					handleLikeClick={handleLikeClick} handleDeleteLikeClick={handleDeleteLikeClick} 
					goToMusicPlayer={() => { setCurrentPage('musicPlayer')}}/>
				:''
			}
		</div>
	)
}

function SmoothiApp({logOutClick, setAccountDeleted}) {
	const [userSubscribed, setUserSubscribed] = useState(false)
	const [subscriptionEnding, setSubscriptionEnding] = useState(false)
	const [isVerified, setIsVerified] = useState(false)
	const [currentPage, setCurrentPage] = useState('mainMenu')
	const [userChoices, setUserChoices] = useState({})
	const [userPreview, setUserPreview] = useState({})
	const [defaultSettingsSelection, setDefaultSettingsSelection] = useState('menu')
	const [currentSlice, setCurrentSlice] = useState({ 'slice_id':'', 'slice_title':''})
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [onePixelUrl, setOnePixelUrl] = useState('')
	const [goodLocation, setGoodLocation] = useState(false)
	const [stripeCustomerId, setStripeCustomerId] = useState('')
	const [accountType, setAccountType] = useState(process.env.REACT_APP_ACCOUNT_TYPE_0)

	const { loading, error, data, refetch } = useQuery(GET_USER_PROFILE)

	//AppCap.addListener('resume', () => {
	//	refetch()
	//});

	useEffect(() => {
		if (data) {
			setUsername(data.userProfile.user.username)
			setEmail(data.userProfile.user.email)
			setAccountType(data.userProfile.user.userType)
			setUserSubscribed(data.customerSubscribed.subscribed)
			setSubscriptionEnding(data.customerSubscribed.subscriptionEnding)
			setStripeCustomerId(data.customer.stripeCustomerId)
			setIsVerified(data.me.verified)
			setOnePixelUrl(data.userProfile.onePixelUrl)
		}
	}, [data])

	useEffect(()=>{
		if(onePixelUrl) {
			axios.head(onePixelUrl)
      .then((response) => {
        if (response.status === 200) {
          setGoodLocation(true);
        } else if (response.status === 403) {
					setGoodLocation(false)
          //console.log('Geolocation restriction error');
        } else {
          console.log('Other image loading error');
        }
      })
      .catch((error) => {
        console.log(error);
      })
		}
	}, [onePixelUrl, setGoodLocation])

	if (loading) {
		return (
			<SmoothiContainer>
				<ImageBg />
				<ExploreContent>
					<LoadingComponent/>
				</ExploreContent>
			</SmoothiContainer>	
		)
	}
	else if (error) {
		return (
			<SmoothiContainer>
				<ImageBg />
				<ExploreContent>
					<ErrorPageInApp/>
					</ExploreContent>
			</SmoothiContainer>
		)
	}

	const goToMainMenu = () => {
		setCurrentPage('mainMenu')
		setUserChoices({})
		setUserPreview('')
	}

	const goToChoices = () => {
		setCurrentPage('choosing')
		setUserChoices({})
		setUserPreview('')
	}

	const goToSettings = () => {
		setDefaultSettingsSelection('menu')
		setCurrentPage('settings')
		setUserChoices({})
		setUserPreview('')
	}

	const goToSettingsFromTooManyRequests = () => {
		setDefaultSettingsSelection('account')
		setCurrentPage('settings')
		setUserChoices({})
		setUserPreview('')
	}

	const goToTooManyRequests = () => {
		setCurrentPage('tooManyRequests')
		setUserChoices({})
		setUserPreview('')
	}

	

	const MainMenu = () => {
		return (
			<SmoothiContainer>
				<ImageBg/>
				<WebLogo/>
				<ExploreContent>
					
					<MenuSection
						goToChoices={() => { setCurrentPage('choosing') }}
						setCurrentSlice={setCurrentSlice}
						goToPreview2={() => { setCurrentPage('preview') }} 
					/>
				</ExploreContent>
			</SmoothiContainer>
		)
	}

	const Choosing = () => {
		return(
			<ChoosingContainer>
				<ImageBg/>
				<WebLogo/>
				<ChoosingContent>
					<ChoicesSection
						goToPlayerPage={() => { setCurrentPage('playerPage') }}
						setUserChoices={setUserChoices} 
						goToMainMenu={goToMainMenu}/>
				</ChoosingContent>
			</ChoosingContainer>
		)
	}

	const Settings = ({refetchUserData, email, username, accountType, userSubscribed, subscriptionEnding, stripeCustomerId, setAccountDeleted}) => {
		return(
			<div>
				<ImageBg/>
				<WebLogo/>
				<SettingsContent>
					<SettingsSection 
						defaultSettingsSelection={defaultSettingsSelection}
						logOutClick={logOutClick} 
						isLoggedIn={true} 
						refetchUserData={refetchUserData}
						email={email}
						username={username}
						accountType={accountType}
						userSubscribed={userSubscribed}
						subscriptionEnding={subscriptionEnding}
						stripeCustomerId={stripeCustomerId}
						setAccountDeleted={setAccountDeleted}/>
				</SettingsContent>
			</div>
		)
	}

	const TooManyRequests = () => {
		return(
			<TooManyMixesPage goToMainMenu={goToMainMenu} goToSettings={goToSettingsFromTooManyRequests}/>
		)
	}

	const Preview = () => {
		return(
			<SmoothiContainer>
				<ImageBg />
				<WebLogo/>
				<ExploreContent>
					<PreviewSection
						goToPlayer={() => { setCurrentPage('playerPage') }}
						setUserPreview={setUserPreview} 
						currentSlice={currentSlice}
						goToMainMenu={goToMainMenu}
					/>
				</ExploreContent>
			</SmoothiContainer>
		)
	}

	if (data && !loading) {
		if (!goodLocation) {
			return(
				<WrongCountryPage 
					logOutClick={logOutClick} 
					appError={'Smoothi is not yet available in your country!'}/>
			)
		}
		// Check if user is verified
		else if (!isVerified) {
			return(
				<SmoothiContainer>
					<ImageBg />
					<ExploreContent>
						<VerifyEmailPage logOutClick={logOutClick} email={email}/>
					</ExploreContent>
				</SmoothiContainer>
			)
		}
	}

	const ReturnMenu = () => {
		switch (currentPage){
			case 'mainMenu':
				return <MainMenu/>
			case 'choosing':
				return <Choosing/>
			case 'tooManyRequests':
				return <TooManyRequests/>
			case 'settings':
				return (
					<Settings refetchUserData={refetch}
						email={email}
						username={username}
						accountType={accountType}
						userSubscribed={userSubscribed}
						subscriptionEnding={subscriptionEnding}
						stripeCustomerId={stripeCustomerId}
						setAccountDeleted={setAccountDeleted}/>
				)
			case 'preview':
				return <Preview/>
			default:
				return (
					<></>
				)
		}
	}

	return(
		<div>
			{!(currentPage === 'musicPlayer' || currentPage === 'playerPage')? <ReturnMenu/>:<div/>}
			<SmoothiAppPlayer logOutClick={logOutClick} currentPage={currentPage} 
				setCurrentPage={setCurrentPage} goToMainMenu={goToMainMenu}
				userChoices={userChoices} setUserChoices={setUserChoices}
				userPreview={userPreview} setUserPreview={setUserPreview}
				userSubscribed={userSubscribed} goToTooManyRequests={goToTooManyRequests}/>
			<Footer currentPage={currentPage}  goToSettings={goToSettings} 
				goToMainMenu={goToMainMenu} goToChoices={goToChoices} 
				logOutClick={logOutClick} isLoggedIn={true} onMenu={true}/>
		</div>
	)
	
}

export default SmoothiApp

